import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {MDXRenderer} from 'gatsby-plugin-mdx';
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../styles/styles.css";

const ResourcesPage = () => {
    const data = useStaticQuery(graphql`
    query resourcesQuery {
        allAirtable(filter: {table: {eq: "Resources"}}) {
            nodes {
                data {
                ResourceTitle
                ResourceCategoryTitle
                ResourceURL
                last_modified_time
                created_time
                ResourceDescription {
                    childMdx {
                        body
                    }
                }
                }
            }
            }
        }
    `)
    const categories = new Set();
    const resourcesByCategory = {};

    data.allAirtable.nodes.forEach((resource) => {
        categories.add(resource.data.ResourceCategoryTitle);
        if (!resourcesByCategory[resource.data.ResourceCategoryTitle]) {
            resourcesByCategory[resource.data.ResourceCategoryTitle] = [];
        }
        resourcesByCategory[resource.data.ResourceCategoryTitle].push(resource);
    });

    return (
        <Layout>
            <SEO title="Resources" />
            <div className="page-container">
                <h1>Resources</h1>
                <div className="resource-container">
                {
                    Object.keys(resourcesByCategory).map((category) => {
                        return <>
                                <h2>{category}</h2>
                                <section key={category}>
                                <div class="column">
                                    {resourcesByCategory[category].map((resource, index) => {
                                        if (index >= resourcesByCategory[category].length / 2) return null;
                                        return <div key={`${category}-${index}`}>
                                            <h3>
                                                <OutboundLink href={resource.data.ResourceURL}>
                                                    {resource.data.ResourceTitle}
                                                </OutboundLink>
                                            </h3>
                                            <MDXRenderer components={{
                                                p: props => <h5 {...props} /> // eslint-disable-line jsx-a11y/heading-has-content
                                            }}>{resource.data.ResourceDescription.childMdx.body}</MDXRenderer>
                                        </div>
                                    })}  
                                </div>
                                <div class="column">
                                    {resourcesByCategory[category].map((resource, index) => {
                                        if (index < resourcesByCategory[category].length / 2) return null;
                                        return <div key={`${category}-${index}`}>
                                            <h3>
                                                <OutboundLink href={resource.data.ResourceURL}>
                                                    {resource.data.ResourceTitle}
                                                </OutboundLink>
                                            </h3>
                                            <MDXRenderer components={{
                                                p: props => <h5 {...props} /> // eslint-disable-line jsx-a11y/heading-has-content
                                            }}>{resource.data.ResourceDescription.childMdx.body}</MDXRenderer>
                                        </div>
                                    })}  
                                </div>
                        </section>
                        </>
                    })
                }
                </div>
            </div>
        </Layout>
    );
};

export default ResourcesPage;